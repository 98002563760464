import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { BsTwitterX } from "react-icons/bs";

const mapStateToProps = state => {
    return { isDarkMode: state.isDarkMode };
};

class SideBar extends Component {
    render() {
        return (
            <div className="side-bar">
                <div>
                    <Link to="/about" className={`side-bar-element ${this.props.isDarkMode ? "sidebar-element-darkmode" : ""}`}>
                        من نحن
                    </Link>

                    <br />

                    <Link to="/tos" className={`side-bar-element ${this.props.isDarkMode ? "sidebar-element-darkmode" : ""}`}>
                        شروط الخدمة
                    </Link>

                    <br />

                    <Link to="/privacy" className={`side-bar-element ${this.props.isDarkMode ? "sidebar-element-darkmode" : ""}`}>
                        سياسة الخصوصيّة
                    </Link>
                </div>
                <div dir="ltr" className={`side-bar-element ${this.props.isDarkMode ? "sidebar-element-darkmode" : ""}`}>
                    <div className="social-network-div">
                        <a href="https://twitter.com/STechpark62878" className="side-bar-link"><BsTwitterX />
                        </a>
                    </div>
                </div>
            </div >
        )
    }
}

export default connect(mapStateToProps)(SideBar);
import './App.css';
import { Container, Spinner, Button } from 'react-bootstrap';
import Home from './Home';
import Profile from './Profile';
import Settings from './Settings';
import AddNote from './AddNote';
import EditNote from './EditNote';
import Reviews from './Reviews';
import Reports from './Reports';
import News from './News';
import Login from './Login';
import SignUp from './SignUp';
import NavBar from './NavBar';
import ReadMore from './ReadMore';
import About from './About';
import PrivacyPolicy from './PrivacyPolicy';
import { Helmet } from 'react-helmet';
import Terms from './Terms';
import Search from './Search';

import {
  Switch,
  Route,
  Redirect,
  BrowserRouter
} from "react-router-dom";
import React, { Component } from 'react';
import firebase from './Firebase';

function PrivateRoute({ component: Component, auth, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => auth === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}

function PublicRoute({ component: Component, auth, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => auth === false
        ? <Component {...props} />
        : <Redirect exact to='/' />}
    />
  )
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: false,
      loading: true,
      showSearchModal: false
    };
  }

  handleOpenSearchModal = () => {
    this.setState({ showSearchModal: true });
  };

  handleCloseSearchModal = () => {
    this.setState({ showSearchModal: false });
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          auth: true,
          loading: false
        })
      } else {
        this.setState({
          auth: false,
          loading: false
        })
      }
    });
  }

  render() {
    return this.state.loading === true ? <Spinner animation="border" style={{ color: "#e95950" }} size="sm" />
      : (
        <div>
          <Helmet>
            <meta property="og:url" content={"https://www.soumar-techpark.com/"} />
            <meta name="description" content="موقع عربي يهتمّ بالتقنية، أكثر ما نهتمّ به هُو الجودة والبساطة" />
            <meta name="theme-color" content="rgb(225, 48, 108)" />
            <meta property="og:image" content="soumar-tech-park.jpg" />
            <meta property="og:title" content="Soumar Tech Park" />
            <meta property="og:image:width" content="500" />
            <meta property="og:image:height" content="500" />
            <meta name="og:type" content="website" />
          </Helmet>

          <Container fluid>
            <BrowserRouter className="app-content">
              <NavBar auth={this.state.auth} handleOpenSearchModal={this.handleOpenSearchModal} />
              <div style={{ paddingTop: "100px", paddingBottom: "100px", paddingLeft: "10px", paddingRight: "10px" }}>
                {/* <Analytics id="UA-216066430-1"> */}
                <Switch>
                  <div>
                    <Search showSearchModal={this.state.showSearchModal} handleCloseSearchModal={this.handleCloseSearchModal} />

                    <Route path="/" exact>
                      <Home auth={this.state.auth} />
                    </Route>

                    <PublicRoute auth={this.state.auth} path="/login" component={Login} exact />

                    <PublicRoute auth={this.state.auth} path="/signup" component={SignUp} exact />

                    <PrivateRoute auth={this.state.auth} path="/profile" component={Profile} exact />

                    <PrivateRoute auth={this.state.auth} path="/settings"
                      component={Settings} exact />

                    <PrivateRoute auth={this.state.auth} path="/addnote"
                      component={AddNote} exact />

                    <PrivateRoute auth={this.state.auth} path="/editnote/:noteid"
                      component={EditNote} exact />

                    <Route path="/reviews/:title" component={ReadMore} />
                    <Route path="/reports/:title" component={ReadMore} />
                    <Route path="/news/:title" component={ReadMore} />

                    <Route path="/reviews" exact>
                      <Reviews auth={this.state.auth} />
                    </Route>

                    <Route path="/reports" exact>
                      <Reports auth={this.state.auth} />
                    </Route>

                    <Route path="/news" exact>
                      <News auth={this.state.auth} />
                    </Route>

                    <Route path="/about" exact>
                      <About />
                    </Route>

                    <Route path="/privacy" exact>
                      <PrivacyPolicy />
                    </Route>

                    <Route path="/tos" exact>
                      <Terms />
                    </Route>

                    {/*<Route render={() => <h3>No Match</h3>} />*/}
                  </div>
                </Switch>
                {/* </Analytics> */}
              </div>
            </BrowserRouter>
          </Container>
        </div >
      )
  }
}

export default App;
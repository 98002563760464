import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col } from 'react-bootstrap';

const mapStateToProps = state => {
    return { username: state.username };
};

class Settings extends Component {
    render() {
        return (
            <Row>
                <Col>
                </Col>
                <Col>
                    الاعدادات، قيد العمل حالياً.
                </Col>
                <Col>
                </Col>
            </Row>
        )
    }
}

export default connect(mapStateToProps)(Settings);
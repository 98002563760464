import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Container } from 'react-bootstrap';
import { AiOutlineMail } from 'react-icons/ai';

const mapStateToProps = state => {
    return { username: state.username, isDarkMode: state.isDarkMode };
};

class About extends Component {
    constructor(props) {
        super(props);
        document.title = "من نحن";
        this.props.isDarkMode ?
            document.body.style.background = "black" : document.body.style.background = "white";
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <Row className={`${this.props.isDarkMode ? "content-darkmode" : "content"}`}>
                <Col xs={12} md={12} className="not-to-copy">
                    <Container>
                        <p className={` fade-in site-title ${this.props.isDarkMode ? "site-title-darkmode" : ""}`} style={{ textAlign: "center" }}>
                            Soumar Tech Park
                        </p>

                        <p dir="rtl" className="text-align-right about-content">
                            <br />
                            قُمتُ بتأسيس هذا الموقع من أجل جميع محبي التقنية، هدفي هو كتابة محتوى مُمتع وبجودة عالية من أجل أن يستفيد الجميع.
                            أهمّ ما حرصت على فعله أثناء انشاء الموقع هُو ابقاؤه بسيطًا من أجل أن يكون سهل التصفح.
                            <br />
                            لو كانت لديك ملاحظات او تعليقات لا تتردّد في مراسلتنا على البريد الالكتروني للموقع أسفله.
                            أتمنى لكَ تقضية وقت مُمتع هُنا.
                            <br />
                            <br />
                            <b> سومر الربيعي، مؤسس الموقع </b>
                        </p>

                        <br />

                        <div dir="ltr" className="about-content text-align-left">
                            <div>
                                <br />
                                <AiOutlineMail /> <a href="mailto:soumar.tech.park@gmail.com">soumar.tech.park@gmail.com</a>
                            </div>
                            <br />

                            Designed and created by Soumar Rubaie.
                            <br />
                            © 2024 Soumar Rubaie.  All rights reserved.
                            Montréal, Québec, Canada.
                        </div>
                    </Container>
                </Col>
                <Col>
                </Col>
            </Row >
        )
    }
}

export default connect(mapStateToProps)(About);
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import firebase from './Firebase';
import { clearAllData, setDarkMode } from "./actions/index";
import { HiOutlineMenuAlt4 } from 'react-icons/hi';
import { BsFillSunFill, BsFillMoonFill, BsController } from 'react-icons/bs';
import {
    NavLink
} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { SlGameController } from "react-icons/sl";

const mapStateToProps = state => {
    return { username: state.username, isDarkMode: state.isDarkMode, currentArticleName: state.currentArticleName };
};

function mapDispatchToProps(dispatch) {
    return {
        clearAllData: () => dispatch(clearAllData()),
        setDarkMode: () => dispatch(setDarkMode()),
    };
}

class NavBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            scrolled: false,
            scrollY: 0, // Store the scroll position
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    logOutUser = () => {
        this.props.clearAllData({});
        firebase.auth().signOut();
    };

    changeDarkModeState = () => {
        this.props.setDarkMode({});
        this.props.isDarkMode ?
            document.body.style.background = "white" : document.body.style.background = "black";
    }

    handleScroll = () => {
        if (window.scrollY > this.state.scrollY) {
            this.setState({ scrolled: true });
        } else {
            this.setState({ scrolled: false });
        }
        this.setState({ scrollY: window.scrollY });
    };

    handleClose = () => {
        this.setState({ expanded: false });
    };

    render() {
        const { scrolled } = this.state;
        const { scrollY } = this.state;
        const { location } = this.props;

        var isShowTitleInNavBar = scrolled && scrollY > 600 && (location.pathname.toString().startsWith('/reports/') || location.pathname.toString().startsWith('/news/') || location.pathname.toString().startsWith('/reviews/'));

        return (
            <Navbar fixed="top" expand="lg" expanded={this.state.expanded} className={`navbar-fixed-top navbar-style ${this.props.isDarkMode ? "navbar-fixed-top navbar-style-darkmode" : ""}`} >
                {!isShowTitleInNavBar &&
                    <Navbar.Brand href="/" className="mx-auto text-center">
                        <div className={`site-title ${this.props.isDarkMode ? "site-title-darkmode" : ""}`}>
                            <SlGameController className='ml-2' />
                            Soumar Tech Park
                        </div>
                    </Navbar.Brand>}

                {!isShowTitleInNavBar &&
                    < Navbar.Toggle onClick={() => this.setState({ expanded: !this.state.expanded })} className={`element-light-mode ${this.props.isDarkMode ? "element-dark-mode" : ""}`} aria-controls="basic-navbar-nav" style={{ border: "none" }}>
                        <HiOutlineMenuAlt4 />
                    </Navbar.Toggle>
                }

                {isShowTitleInNavBar &&
                    < Navbar.Text className="mx-auto text-center" style={{ fontSize: "large" }}>
                        <b className={`element-light-mode ${this.props.isDarkMode ? "element-dark-mode" : ""}`} >{this.props.currentArticleName}</b>
                    </Navbar.Text>
                }

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {!isShowTitleInNavBar &&
                            <>
                                <Nav.Link to="/" onClick={this.handleClose} exact as={NavLink} className={`${this.props.isDarkMode ? "navbar-element-darkmode" : ""}`} activeClassName={`${this.props.isDarkMode ? "nav-link-active-dark-mode" : "nav-link-active"}`} >الرئيسيّة</Nav.Link>

                                {this.props.auth &&
                                    <Nav.Link to="/profile" onClick={this.handleClose} exact as={NavLink} className={`${this.props.isDarkMode ? "navbar-element-darkmode" : ""}`} activeClassName={`${this.props.isDarkMode ? "nav-link-active-dark-mode" : "nav-link-active"}`}>الملف الشخصي</Nav.Link>}

                                {this.props.auth &&
                                    <Nav.Link to="/addnote" onClick={this.handleClose} exact as={NavLink} className={`${this.props.isDarkMode ? "navbar-element-darkmode" : ""}`} activeClassName={`${this.props.isDarkMode ? "nav-link-active-dark-mode" : "nav-link-active"}`}>أضف تدوينة</Nav.Link>}

                                <Nav.Link to="/reviews" onClick={this.handleClose} exact as={NavLink} className={`${this.props.isDarkMode ? "navbar-element-darkmode" : ""}`} activeClassName={`${this.props.isDarkMode ? "nav-link-active-dark-mode" : "nav-link-active"}`}>
                                    مراجعات
                                </Nav.Link>

                                <Nav.Link to="/news" onClick={this.handleClose} exact as={NavLink} className={`${this.props.isDarkMode ? "navbar-element-darkmode" : ""}`} activeClassName={`${this.props.isDarkMode ? "nav-link-active-dark-mode" : "nav-link-active"}`}>أخبار</Nav.Link>

                                <Nav.Link to="/reports" onClick={this.handleClose} exact as={NavLink} className={`${this.props.isDarkMode ? "navbar-element-darkmode" : ""}`} activeClassName={`${this.props.isDarkMode ? "nav-link-active-dark-mode" : "nav-link-active"}`}>تقارير</Nav.Link>

                                {this.props.auth &&
                                    <Nav.Link onClick={this.handleClose} to="/settings" exact as={NavLink} className={`${this.props.isDarkMode ? "navbar-element-darkmode" : ""}`} activeClassName={`${this.props.isDarkMode ? "nav-link-active-dark-mode" : "nav-link-active"}`}>الإعدادات</Nav.Link>}

                                {this.props.auth &&
                                    <Nav.Link onClick={this.logOutUser} to="" exact as={NavLink} className={`${this.props.isDarkMode ? "navbar-element-darkmode" : ""}`} activeClassName={`${this.props.isDarkMode ? "nav-link-active-dark-mode" : "nav-link-active"}`}>تسجيل الخروج</Nav.Link>}

                                <Nav.Link onClick={this.props.handleOpenSearchModal} className={`${this.props.isDarkMode ? "navbar-element-darkmode" : ""}`}>
                                    <i class="bi bi-search ml-1"></i> البحث
                                </Nav.Link>
                            </>}

                    </Nav>

                    <Nav className="mr-auto" style={{ fontSize: "15px" }}>
                        {
                            this.props.isDarkMode ?
                                (<Nav.Link className={`${this.props.isDarkMode ? "btn-no-hover-dark-mode" : "btn-no-hover"}`} onClick={this.changeDarkModeState}>
                                    <BsFillSunFill style={{ color: "#f9d71c" }} />
                                </Nav.Link>) :
                                (<Nav.Link className={`${this.props.isDarkMode ? "btn-no-hover-dark-mode" : "btn-no-hover"}`} onClick={this.changeDarkModeState}>
                                    <BsFillMoonFill style={{ color: "grey" }} />
                                </Nav.Link>)
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar >
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
